import React, { useContext } from 'react';
import Tcms from "../../../tcms/Tcms";
import { FilterContext } from "../../../contexts/FilterContext";
import { TcmsContext } from '../../../contexts/TcmsContext';

export default function Sort(props) {
  const context = useContext(TcmsContext);
  const filterContext = useContext(FilterContext);
  const count = filterContext.filteredProductCount;

  return (
    <>
      <div className="product-top-bar">
        <div className='gift-title'>
          <Tcms>Kies je geschenk</Tcms>
        </div>
        <div className="product-top-bar__left">
          <span>{count}</span> <Tcms>{'product' + (count === 0 || count > 1 ? 'en' : '')}</Tcms>
        </div>
        {context.meta.no_price === 0 && context.meta.wso_id != 953 &&
          <div className="product-top-bar__right">
            <div className="sortering">
              <ul>
                <li className={filterContext.sort === 'desc' ? 'active' : ''}
                    onClick={() => filterContext.setSort(filterContext.sort === 'desc' ? 'custom' : 'desc')}>
                  <Tcms>Prijs hoog - laag</Tcms>
                </li>
                <li className={filterContext.sort === 'asc' ? 'active' : ''}
                    onClick={() => filterContext.setSort(filterContext.sort === 'asc' ? 'custom' : 'asc')}>
                  <Tcms>Prijs laag - hoog</Tcms>
                </li>
              </ul>
            </div>
          </div>
        }
      </div>
    </>
  )
}
