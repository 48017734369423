import React, { useContext, useState } from 'react';
import Menu from "../../../components/webshop/menu/Menu";
import Banner from "../../../components/webshop/banner/Banner";
import { TcmsContext } from '../../../contexts/TcmsContext';
import Tcms from '../../../tcms/Tcms';
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import Background from '../../../components/webshop/background/Background';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Footer from '../../../components/webshop/footer/Footer';

export default function Info() {
  const [activeIndex, setActiveIndex] = useState(false);
  const context = useContext(TcmsContext)

  const tabs = ['header_1','header_2','header_3','header_4','header_5','header_6','header_7','header_8','header_9'];

  const checkDisabled = (value) => {
    return !!value;
  }

  return (
    <React.Fragment>
      <Menu/>
      <Banner/>
      <div className='page-wrapper'>
        <Background/>
        <div className="faq_container container__large content-page page-info main-view">
          <div className="p-grid">
            <div className="p-col-12 text-center">
              <TcmsH1>Extra_info</TcmsH1>
            </div>
            <div className="p-col-12 text-center">
              {context.editMode &&
                <div className='tcms-tabs-edit'>
                  {tabs.map((tab, i) => (
                    <Tcms key={i}>{tab}</Tcms>
                  ))}
                </div>
              }
              <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {tabs.map((tab, i) => {
                  if (checkDisabled(Tcms(tab))) {
                    return (
                      <AccordionTab key={i} header={Tcms(tab)}>
                        <TcmsWYSIWYG>{`tab_content_${i + 1}`}</TcmsWYSIWYG>
                      </AccordionTab>
                    );
                  }
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </React.Fragment>
  )
}
