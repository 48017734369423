import Product from './product/Product'
import React, {useState, useContext, useEffect} from 'react'
import {TcmsContext} from '../../contexts/TcmsContext'
import {FilterContext} from '../../contexts/FilterContext'
import {useHistory} from "react-router-dom";
import slug from "../../services/slug";
import { Card } from 'primereact/card';
import TcmsImg from '../../tcms/Tags/TcmsImg';
import Tcms from '../../tcms/Tcms';
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';

/**
 * Virtual sets the replace func cuz its a popup
 */

function WebshopProducts({replaceWithPdp}) {

  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)

  const [products, setProducts] = useState()
  const [hoofdCats, setHoofdCats] = useState()
  const [categories, setCategories] = useState()
  const [init, setInit] = useState(false)

  const history = useHistory()

  const customProducts = ['CP0','CP1','CP2','CP3','CP4','CP5'];

  // Mount
  useEffect(() => {
    setProducts(context.products.products)
    setCategories(context.products.categories)
    setHoofdCats(context.products.categories.filter((el) => el.parent_id === 4))
  }, [context.products.products, context.products.categories])

  useEffect(() => {
    const productSort = (products, sort) => {
      switch (sort) {
        case 'asc':
          products.sort((a, b) => a.price_in_eco - b.price_in_eco)
          break;
        case 'custom':
          products.sort((a, b) => {
            return a.display_name > b.display_name ? 1 : -1
          })
          break;
        case undefined:
        case 'desc':
          products.sort((a, b) => b.price_in_eco - a.price_in_eco)
          break;
        default:
      }

      if (sort == 'custom') {
        products.sort((a,b) => (a.local_order < b.local_order) ? 1 : (b.local_order < a.local_order ? -1 : 0))
      }

      // This originalIndex is there for the 'animation' part when clicking an element
      let originalIndex = 0
      products.forEach(e => e.originalIndex = originalIndex++)

      return [...products]

    }

    const runProductFilter = () => {

      let activeCategories = []

      if (filterContext.activeHoofdCatId == 4) {

        activeCategories = categories.flatMap(el => {
          return el.id;
        });

      } else {
        if (filterContext.activeHoofdCatId === 17) {
          /**
           * When hoofd cat = 17 (Geschenken) we filter on categories
           * (and not on the 'hoofd' category)
           */
          activeCategories = [...filterContext.activeSubCats]
  
          if (activeCategories.length === 0) {
            activeCategories = categories.flatMap(el => {
              return el.parent_id === 17 ? el.id : []
            })
          }
        } else {
  
          /**
           * We only filter on the hoofd cat (17) like Giftcards Subscriptions e.t.c.
           */
          activeCategories.push(filterContext.activeHoofdCatId)
        }
      }

      // And apply filter

      let products = context.products.products.filter(el => {

        let name, search
        if(filterContext.search) {
          name = el.display_name.toLowerCase()
          search = filterContext.search.toLowerCase()
        }

        let ribbon = true;

        if (filterContext.ribbons.length) {
          if (filterContext.ribbons.filter(value => el.ribbon_ids.includes(value)).length) {
            ribbon = true;
          } else {
            ribbon = false
          }
        } else {
          if (filterContext.customRibbon) {
            if (el.ribbon_ids.includes(filterContext.customRibbon)) {
              ribbon = true;
            } else {
              ribbon = false;
            }
          } else {
            ribbon = true;
          }
        }

        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
          setTimeout(() => {
            window.scroll({
              top: parseInt(scrollPosition) - 100, 
              behavior: 'smooth' 
            });
          }, 1000);
          sessionStorage.removeItem("scrollPosition")
        } else {
          if (context.meta.custom_header) {
            window.scrollTo(0, 450)
          }
        }

        return (
             activeCategories.includes(el.category_id)                                                                      // Category
          && el.price_in_eco <= filterContext.maxPrice                                                                      // Slider
          && (ribbon) // Ribbons
          && (!search || name.indexOf(search) !== -1)
        )
      })



      // Now sort them prods
      products = productSort(products, filterContext.sort)

      products.sort((a,b) => (a.product_type < b.product_type) ? 1 : ((b.product_type < a.product_type && a.product_type != "service" && b.product_type != "service") ? -1 : 0))

      // And set them prods
      setProducts(products)
      filterContext.setFilteredProductCount(products.length)
      setInit(true)

      


    }

    if (hoofdCats) {
      runProductFilter()
    }

  }, [
    context.products.products,
    context.favorites,
    hoofdCats,
    categories,
    filterContext.activeHoofdCatId,
    filterContext.activeSubCats,
    filterContext.sort,
    filterContext.maxPrice,
    filterContext.ribbons,
    filterContext
  ])

  const array_chunk = function (arr, size) {
    if (!Array.isArray(arr)) {
      throw new TypeError('Input should be Array');
    }
  
    if (typeof size !== 'number') {
      throw new TypeError('Size should be a Number');
    }
  
    var result = [];
    for (var i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, size + i));
    }
  
    return result;
  };

  if (!products) return null

  if(!init) return null

  let skipDoubles = [];
  let uniqueProducts = [];
  products.map((product, i) => {
    if (!skipDoubles['_' + product.product_tmpl_id]) {
      skipDoubles['_' + product.product_tmpl_id] = true
      uniqueProducts.push(product);
    }
    return true;
  });

  const rows = array_chunk(uniqueProducts, 3)

  return (

    rows.map((row, ri) => (
      <div key={ri} className="p-grid">
      {
        row.map((product, i) => (
          <Product key={i}
                          originalIndex={product.originalIndex}
                          id={product.id}
                          display_name={product.display_name}
                          cat_name={product.cat_name}
                          category_id={product.category_id}
                          price_in_eco={product.price_in_eco}
                          stock={product.stock}
                          image_name={product.image_name}
                          ribbon_ids={product.ribbon_ids}
                          ambiance_images={product.ambiance_images}
                          featured={product.featured}
                          productClicked={() => {

                            // filterContext.setActiveSubCats([product.category_id])

                            if(replaceWithPdp){
                              // used in virtual market
                              replaceWithPdp(product.id)
                            } else {
                              sessionStorage.setItem("scrollPosition", window.scrollY);
                              // Normal web page
                              history.push(`/${context.webshopSlug}/product/${product.id}/${slug(product.display_name)}`)
                            }

                          }}/>
        ))
      }
      {ri < 6 &&
        <div className={`product-col p-col-12 p-col-cp cp-${Tcms(customProducts[ri])}`}>
          {context.editMode &&
            <Tcms>{customProducts[ri]}</Tcms>
          }
          <Card className="product-item product-ambiance-item">
            <div className='p-grid'>
              <div className='p-col-12 p-lg-6 col-img'>
                <div className="image">
                  <TcmsImg src={'CP_img_'+ri}></TcmsImg>
                </div>
              </div>
              <div className='p-col-12 p-lg-6 col-text'>
                <div className='text'>
                  <TcmsWYSIWYG>{'CP_text_'+ri}</TcmsWYSIWYG>
                </div>
              </div>
            </div>
          </Card>
        </div>
      }
      </div>
    ))


  )
}

export default WebshopProducts
