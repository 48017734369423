import React from "react";
import "./GameScreenTwo.scss";

const GameScreenTwo = () => {
 
  return (
    <div className="game-screen">
      <div className="game-content"> 
        <h1>Second game</h1>
      </div>

    </div>
  );
};

export default GameScreenTwo;
