import React from "react";
import "./GameScreenThree.scss";

const GameScreenThree = () => {
 
  return (
    <div className="game-screen">
      <div className="game-content"> 
        <h1>Third game</h1>
      </div>

    </div>
  );
};

export default GameScreenThree;
