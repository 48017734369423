import React, { useState, useEffect, useRef } from 'react';
import GameScreenOne from '../first-game/GameScreenOne';
import GameScreenTwo from '../second-game/GameScreenTwo';
import GameScreenThree from '../third-game/GameScreenThree';
import GameScreenFour from '../fourth-game/GameScreenFour';
import GameScreenFive from '../fifth-game/GameScreenFive';
import GameScreenSix from '../sixth-game/GameScreenSix';
import PrizeModal from '../prize-modal/PrizeModal';
import SettingsModal from '../settings-modal/SettingsModal';
import './Games.scss';

// Array of sound URLs for each game
const quizSounds = [
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', // Game 1
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3', // Game 2
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3', // Game 3
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3', // Game 4
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3', // Game 5
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-6.mp3', // Game 6
];

const games = [
  { id: 1, name: "Game 1", prize: "House of Marley headphones", image: "https://static.bax-shop.es/image/product/788022/2900678/cc6cb02e/450x450/1622112199EM-JH151-SB_Product_01_JPG.jpg" },
  { id: 2, name: "Game 2", prize: "Amazon Echo Dot", image: "https://images-na.ssl-images-amazon.com/images/I/6182S7MYC2L._AC_SL1000_.jpg" },
  { id: 3, name: "Game 3", prize: "Google Nest Hub", image: "https://duet-cdn.vox-cdn.com/thumbor/0x0:2040x1360/2400x1600/filters:focal(1046x1005:1047x1006):format(webp)/cdn.vox-cdn.com/uploads/chorus_asset/file/19170899/dseifert_190905_3634_0001.jpg" },
  { id: 4, name: "Game 4", prize: "Fitbit Charge 4", image: "https://i5.walmartimages.com/seo/Fitbit-Charge-4-NFC-Activity-Fitness-Tracker-Rosewood_5f119787-5974-4ee7-b0b4-44bcce9b71b6.f7930101fbfd60c065d80c40741510e8.jpeg" },
  { id: 5, name: "Game 5", prize: "PlayStation 5", image: "https://3dbox.ba/wp-content/uploads/2024/02/PlayStation-5-Slim-Digital-Edition-D-chassis-1.jpg" },
  { id: 6, name: "Game 6", prize: "Apple Watch Series 6", image: "https://jupiter.planet.ba/2507-large_default/apple-watch-series-6-44mm-aluminium-case-pink.jpg" },
];

const gameScreens = {
  1: GameScreenOne,
  2: GameScreenTwo,
  3: GameScreenThree,
  4: GameScreenFour,
  5: GameScreenFive,
  6: GameScreenSix,
};

function Games() {
  const quizAudioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isPrizeModalOpen, setIsPrizeModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [activeGame, setActiveGame] = useState(1);
  const currentGame = games.find(game => game.id === activeGame);

  useEffect(() => {
    if (quizAudioRef.current) {
      quizAudioRef.current.pause();
    }
    const newAudio = new Audio(quizSounds[activeGame - 1]);
    newAudio.muted = isMuted;
    newAudio.volume = volume;
    quizAudioRef.current = newAudio;
    if (isGameStarted && !isMuted) {
      quizAudioRef.current.play();
    }
  }, [activeGame, isMuted, volume, isGameStarted]);

  const handleStartGame = () => setIsGameStarted(true);
  const handleToggleMute = () => setIsMuted(!isMuted);
  const handleVolumeChange = (newVolume) => setVolume(newVolume);
  const openPrizeModal = () => setIsPrizeModalOpen(true);
  const closePrizeModal = () => setIsPrizeModalOpen(false);
  const openSettingsModal = () => setIsSettingsModalOpen(true);
  const closeSettingsModal = () => setIsSettingsModalOpen(false);

  const renderGameScreen = () => {
    const GameScreenComponent = gameScreens[activeGame];
    if (GameScreenComponent) {
      return <GameScreenComponent />;
    }
    return null;
  };

  return (
    <>
      <div className="welcome-screen">
        <header className="header">
          <div className="user-info">
            <img
              src="https://img.icons8.com/ios/50/ffffff/user--v1.png"
              alt="user icon"
              className="user-icon"
            />
            <span>Welkom Jasper</span>
          </div>
          <div className="logo">
            <img
              src="https://contact-telefoonnummer.nl/wp-content/uploads/2018/09/Netherlands-government-logo.jpg"
              alt="logo"
            />
          </div>
          <div className="settings">
            <img
              src={isMuted ? "https://img.icons8.com/ios-filled/50/ffffff/mute.png" : "https://img.icons8.com/ios-filled/50/ffffff/low-volume.png"}
              alt="sound icon"
              className="icon"
              onClick={handleToggleMute}
              style={{ cursor: 'pointer' }}
            />
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/settings.png"
              alt="settings icon"
              className="icon"
              onClick={openSettingsModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </header>

        {!isGameStarted ? (
          <div className="content">
            <div className="text-box">
              <h1>Welkom</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu magna sed orci interdum interdum. Ut id nibh non elit aliquam ultricies sit amet vel est. Nunc odio purus, varius at ultrices ac, rutrum vehicula felis. Nulla mollis sapien id lorem aliquam iaculis. Mauris tempus risus at nisl laoreet. Nunc odio purus, varius at ultrices ac, rutrum vehicula felis.
              </p>
              <button aria-label="Start the game" className="button primary" onClick={handleStartGame}>
                Start het spel
              </button>
              <a href='/webshop' className='welcome_button'>
                <button className="button secondary">Naar de webshop</button>
              </a>
            </div>
            <div className="image-box">
              <img
                src="https://suburbanmen.com/wp-content/uploads/2020/07/need-little-rustic-cabin-woods-20200706-1006.jpg"
                alt="Forest"
              />
              <div className="overlay">
                <p>Start jouw wandeling in de Nederlandse natuur</p>
              </div>
              <button aria-label="Close overlay" className="close-button">X</button>
            </div>
          </div>
        ) : (
          <div className="content">
            {renderGameScreen()}
          </div>
        )}

        <footer className="footer">
          <div className="footer-button-container">
            <a href='/webshop' className="footer-button">
              <img
                src="https://img.icons8.com/ios-filled/50/ffffff/gift.png"
                alt="gift icon"
                className="footer-icon" />
              Naar de webshop
              <span className="arrow-right">{'>'}</span>
            </a>
          </div>
          <div className="steps">
            {games.map(game => (
              <div
                key={game.id}
                className={`step ${game.id === activeGame ? 'active' : ''}`}
                onClick={() => setActiveGame(game.id)}
              >
                {game.id}
              </div>
            ))}
          </div>
          <div className="footer-icons">
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/backpack.png"
              alt="backpack icon"
              className="footer-icon prize"
              onClick={openPrizeModal}
            />
          </div>
        </footer>
      </div>

      <PrizeModal show={isPrizeModalOpen} onClose={closePrizeModal}>
        <h2>Prize for this game is:</h2>
        <p>{currentGame.prize}</p>
        <img src={currentGame.image} alt={currentGame.prize} style={{ width: '100%' }} />
      </PrizeModal>

      <SettingsModal
        show={isSettingsModalOpen}
        onClose={closeSettingsModal}
        onVolumeChange={handleVolumeChange}
        isMuted={isMuted}
        volume={volume}
        onMuteToggle={handleToggleMute}
      />
    </>
  );
}

export default Games;
