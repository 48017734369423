import React, { useContext } from "react";
import Menu from "../../../components/webshop/menu/Menu";
import MyOrders from '../../../components/webshop/MyOrders';
import Tcms from "../../../tcms/Tcms";
import { TcmsContext } from "../../../contexts/TcmsContext";
import Background from "../../../components/webshop/background/Background";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Footer from '../../../components/webshop/footer/Footer';

const styles = {
  pageWrapper: {
    color: '#333',
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    padding: '20px',
  },
  containerBanner: {
    position: 'relative',
    zIndex: 10,
    marginTop: '20px',
    textAlign: 'center',
  },
  containerImage: {
    height: '300px',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    borderRadius: '8px',
  },
  mainView: {
    transform: 'translate(0, -50px)',
    position: 'relative',
    zIndex: 10,
    paddingBottom: '45px',
    maxWidth: '1000px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  orderHeader: {
    padding: '15px 0',
    margin: '0',
    background: 'var(--mtg-1)',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
    textAlign: 'center',
    fontSize: '24px',
  },
  alert: {
    background: '#ffe7e6',
    border: 'solid #ff5757',
    borderWidth: '0 0 0 6px',
    color: '#ff5757',
    padding: '1.25rem 1.75rem',
    margin: '1rem 0',
    borderRadius: '6px',
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

export default function Order(props) {
  const context = useContext(TcmsContext);

  return (
    <React.Fragment>
      <Menu />
      <div style={styles.containerBanner}>
        <TcmsImg src='/media/images/order_header.jpg' style={styles.containerImage} />
      </div>
      <div className='page-wrapper page-orders' style={styles.pageWrapper}>
        <Background />
        <div className="container__large content-page main-view" style={styles.mainView}>
          <div className="order-header" style={styles.orderHeader}>
            <Tcms>Jouw bestellingen</Tcms>
          </div>
          {(context.jwt.usr.uid === 1) && (
            <div style={styles.alert}>
              <Tcms>
                Je bent ingelogd als admin! Deze order wordt niet naar Odoo gestuurd en zal overschreven worden bij een
                volgende bestelling.
              </Tcms>
              <span style={styles.closeBtn}>&times;</span>
            </div>
          )}
          <MyOrders />
        </div>
      </div>
      <Footer/>
    </React.Fragment>
  );
}

