import {ToggleButton} from 'primereact/togglebutton';
import {useContext, useEffect, useState} from "react";
import {TcmsContext} from "../../../contexts/TcmsContext";
import {FilterContext} from "../../../contexts/FilterContext";
import TcmsImg from '../../../tcms/Tags/TcmsImg';

export default function RibbonFilter() {
  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)
  const [ribbons, setRibbons] = useState([])

  const ribbonIcons = context.ribbonIcons;

  useEffect(() => {
    setRibbons(context.meta.ribbons)
  }, [context.meta.ribbons])


  return <div className="ribbon-filter">
    {ribbons.map(el => {

      el.icon = false;
      el.count = 0;

      context.products.products.forEach(product => {
        if (product.ribbon_ids){
          if (product.ribbon_ids.includes(el.id)) {
            el.count += 1;
          }
        }
      })

      if (ribbonIcons[el.name]) {
        el.icon = ribbonIcons[el.name];
      }

      if (filterContext.customRibbon === el.id) {
        el.checked = true;
      }

      return <div className='ribbon-filter-item' id={'ribbon-'+el.id} key={el.id}>
        <div className="ribbon-button">
          <ToggleButton
            onLabel={context.stringTranslate(el.name)}
            offLabel={context.stringTranslate(el.name)}
            key={el.id}
            checked={el.checked}
            onChange={(e) => {
              filterContext.setCustomRibbon(false);
              let clone = [...ribbons]
              clone.find(r=>r.id === el.id).checked = e.value
              // Get al 'checked' ids and pass them to the filter context
              filterContext.setRibbons(clone.filter(r=>r.checked).map(r=>r.id))
              // And update this page
              setRibbons(clone)
            }}/>
            <span className="checkbox"><i className="pi pi-check"></i></span>
            <span className="count">({el.count})</span>
        </div>
        {el.icon ? 
          <TcmsImg src={el.icon}/>
        :
          undefined
        }
      </div>
    })}
  </div>
}
