import {Dialog} from "primereact/dialog";
import Tcms from "../../../tcms/Tcms";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {useHistory} from "react-router-dom";
import {TcmsContext} from "../../../contexts/TcmsContext";

const DialogLogout = forwardRef((props, ref) => {
  const context = useContext(TcmsContext)
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <Dialog
    header={
      <div>
        <TcmsImg className="dialog-header-img img-left" src="/media/images/christmas_branch_l.png"></TcmsImg> 
        <TcmsImg className="dialog-header-img img-right" src="/media/images/christmas_branch_r.png"></TcmsImg> 
      </div>
    }
    className={`wso-`+context.meta.wso_id}
    visible={visible}
    style={{width: '40em'}}
    // position={}
    modal={true}
    onHide={() => setVisible(false)}>
    <div className="p-grid">
      <div className="p-col-12">
        <Tcms>Weet je zeker dat je wil uitloggen?</Tcms>
      </div>
      <div className="p-col-6">
        <TcmsButton
          style={{width: '10rem'}}
          className="p-button-outlined p-button-secondary"
          onClick={() => setVisible(false)}
          label="Cancel"/>
      </div>
      <div className="p-col-6">
        <TcmsButton
          style={{float: 'right', width: '10rem'}}
          className="p-button-info"
          onClick={() => {
            context.logout()
            history.push('/' + context.webshopSlug)
          }}
          label="OK"/>
      </div>
    </div>
  </Dialog>

})
export default DialogLogout
