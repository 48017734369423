import React from 'react';
import TcmsImg from "../../../tcms/Tags/TcmsImg";

class Thuiswinkel extends React.Component {
    render() {
        return (
            <div className="thuiswinkel">
                 <TcmsImg
                    alt="thuiswinkel"
                    src={`/media/icons/thuiswinkel-logo.png`}/>
            </div>
        );
    }
}

export default Thuiswinkel;