import React, {Suspense, Component} from 'react'
import axios from 'axios'
import doubleSnailMailedVoucherCodes from '../services/fuckups/doubleSnailMailedVoucherCodes'

import {TcmsContext} from '../contexts/TcmsContext'

import PageLoading from "../components/webshop/PageLoading";
import Tcms from "../tcms/Tcms";

// Need all them skins definded
// const SkinDefault = React.lazy(() => import('./skins/default/Login'));
import SkinDefault from './skins/default/Login'
import DoubleVoucherPopup from "../services/fuckups/DoubleVoucherPopup";

// Themes (We have no default theme, that will be the default skin)
const Theme2 = React.lazy(() => import('./themes/2-women/Login')); // eslint-disable-line
const Theme3 = React.lazy(() => import('./themes/3-men/Login')); // eslint-disable-line

// Skins
// const Skin86 = React.lazy(() => import('./skins/86-gemeente-ede/Login')); // eslint-disable-line

/**********************************************************************************
 *              The login page does NOT extend the Auth Component!                *
 **********************************************************************************/
const API = process.env.REACT_APP_MTG_BACKEND + '/api'

class Login extends Component {

  static contextType = TcmsContext
  refDoubleVoucherPopup = null
  state = {
    user: '',
    pass: '',
    loading: false,
    linkLogin: false,
    locale: 'nl_nl',
    welcome_screen: 0,
  }

  constructor(props) {
    super(props);
    this.refDoubleVoucherPopup = React.createRef()
  }

  mtgComponentDidMount() {
    // Can have an URL like so /mtg-login/#/slug/hash. It is a link login
    // So when we detect one try run it
    const hash = document.location.hash.split('/');
    if (hash.length > 1) {
      this.setState({linkLogin:true})
    }

    // Get info from BE
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/partner-data', {
      params: {
        slug: this.props.match.params.webshopslug
      }
    }).then(r => {

      if (r.data.local_en_us) {
        this.context.setLanguageCode('en_us');
        this.state.locale = 'en_us';
      }

      this.state.welcome_screen = r.data.welcome_screen
      this.context.setWebshopId(r.data.wso_id)
      this.context.setWebshopSlug(r.data.slug)
      this.context.setThemeId(r.data.theme_id)
      this.context.setFontFamilyLink(() => {
        return r.data.font_family_link
      })

      this.context.setFontFamilyValue(()=>{
        return r.data.font_family
      })

      if (hash.length > 1) {
        this.linkLogin(hash)
      }
    })
  }

  /**
   *
   * !! LINK LOGIN !!
   *
   * Post the user and the token
   *
   */
  linkLogin(hash) {
    
    const slug = this.context.webshopSlug
    const user = hash[1]
    const token = hash[2]
    const page = ['email'].includes(hash[3]) ? hash[3] : false

    // Cosmetic stuff. It should do nothing :)
    this.setState({
      user,
      pass: '********',
      loading: true
    })

    axios.post(API + '/webshop/link-login', {slug, user, token}).then(res => {
      this._login(res, page )
    }).catch(err => {
      this._err(err)
    })
  }

  /**
   * Human login
   */
  submit() {
    axios.post(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/login', this.state).then(
      (res) => {
        this._login(res)
      }
    ).catch((err) => {
      this._err(err)
    })
  }

  _login = (res, page) => {

    this.state.welcome_screen = res.data.welcome_screen

    if(doubleSnailMailedVoucherCodes.includes(this.state.user)){
      // Fuckup preventor
      this.refDoubleVoucherPopup.current.open()


    } else {
      // Normal flow
      // This is JWT
      sessionStorage.setItem('jwt_token', res.data.jwt_token);
      axios.defaults.headers.common['MtgJwt'] = res.data.jwt_token;

      this.context.login(res.data.jwt_token)

      if (page) {
        this.props.history.push('/' + this.context.webshopSlug + '/' + page)
      } else {
        if (this.state.welcome_screen === 1) {
          // let vi = '/' + this.context.webshopSlug + '/welkom'
          let vi = '/' + this.context.webshopSlug + '/home'
          if (document.location.pathname === '/') {
            document.location.pathname = vi
          } else {
            this.props.history.push(vi)
          }
        } else {
          if (this.context.virtualMarketSlug) {
            let vi = '/' + this.context.webshopSlug + '/virtueel'
            // To virtual market
            if (document.location.pathname === '/') {
              document.location.pathname = vi
            } else {
              this.props.history.push(vi)
            }
          } else {
            let ws = '/' + this.context.webshopSlug + '/portal'
            if (this.context.webshopId === 725) {
              ws = '/' + this.context.webshopSlug + '/webshop'
            }
            if (document.location.pathname === '/') {
              document.location.pathname = ws
            } else {
              this.props.history.push(ws)
            }
          }
        }
      }
    }

  }
  _err = (err) => {
    this.setState({
      loading: false,   // Stop the loading
      linkLogin: false  // Kill the link login
    },()=>{
      console.log(err);
      this.messages.show({
        severity: 'error',
        detail: <Tcms>{err.response.data.error}</Tcms>,
        closable: true,
        sticky: true
      });
    })

  }

  /*********************************************************************************************************************
   *                        The skin stuff. Do not change. Copy this part to create a new Skin page                    *
   *                        Yes, it's duplicated code. But I think Webpack needs it to load dynamic                    *
   *                        !! Only thing you might do is set pageType (public/private) !!                             *
   ********************************************************************************************************************/

  pageType = 'public'

  componentDidMount() {
    this.context.setLoadedComponent(this.constructor.name);
    if (this.mtgComponentDidMount) this.mtgComponentDidMount()
  }

  render() {
    if (!this.context.translate) return null;

    // If link login dont render
    if(this.state.linkLogin) return null;

    return (
      <Suspense fallback={<PageLoading/>}>
        <>
          <DoubleVoucherPopup ref={this.refDoubleVoucherPopup}/>
          {this.getSkin()}
        </>
      </Suspense>
    )
  }

  getSkin(html) {
    // Check if skin exists or theme exists
    const skinExists = eval('typeof Skin' + this.context.webshopId) === 'undefined' ? false : true // eslint-disable-line no-eval
    const themeExists = eval('typeof Theme' + this.context.themeId) === 'undefined' ? false : true // eslint-disable-line no-eval

    if (skinExists) {
      html = React.createElement(eval('Skin' + this.context.webshopId), {rootComponent: this}) // eslint-disable-line no-eval
    } else if (themeExists) {
      html = React.createElement(eval('Theme' + this.context.themeId), {rootComponent: this}) // eslint-disable-line no-eval
    } else {
      html = <SkinDefault rootComponent={this}/>
    }
    return html
  }

}

export default Login
