import React from "react";
import Menu from "../../../components/webshop/menu/Menu";
import Background from "../../../components/webshop/background/Background";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";


export default function CustomPage(props) {

  const pathname = 'custom_page_'+window.location.pathname.split("/").pop()

  return (

    <React.Fragment>

      <Menu/>
      <div className='page-custom'>
      <Background/> 
        <div className="container__large content-page main-view ">
          <div className="wrapper">
            <TcmsWYSIWYG>{pathname}</TcmsWYSIWYG>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}



