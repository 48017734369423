import React from "react";
import "./GameScreenFive.scss";

const GameScreenFive = () => {

  return (
    <div className="game-screen">
      <div className="game-content">
        <h1>Fifth game</h1>
      </div>

    </div>
  );
};

export default GameScreenFive;
