import React from "react";
import "./GameScreenFour.scss";

const GameScreenFour = () => {
 
  return (
    <div className="game-screen">
      <div className="game-content"> 
        <h1>Fourth game</h1>
      </div>

    </div>
  );
};

export default GameScreenFour;
