import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from "primereact/dialog";
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';

const DialogHome = forwardRef((props, ref) => {

  const [visible, setVisible] = useState();

  useImperativeHandle(ref, () => ({
    open() {
      if (props.toggle === "inactive") {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }));

  return (
    <Dialog
      visible={visible}
      className={props.className}
      position="top"
      modal={true}
      onHide={() => setVisible(false)}
    >
      <div className="dialog-content">
        <TcmsWYSIWYG>home_dialogue_content</TcmsWYSIWYG>
      </div>
      <div className="close-button-container">
        <button
          onClick={() => setVisible(false)}
          className="p-component"
        >
          Ga verder
        </button>
      </div>
    </Dialog>
  );
});

export default DialogHome;
