import React, { useEffect, useContext, useState } from 'react';
import { TcmsContext } from '../../../contexts/TcmsContext';
import { FilterContext } from '../../../contexts/FilterContext';
import { Sidebar } from 'primereact/sidebar';
import Tcms from '../../../tcms/Tcms';
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import MenuItems from './MenuItems';
import TcmsSpan from '../../../tcms/Tags/TcmsSpan';
import classNames from 'classnames';
import Helpdesk from '../helpdesk/Helpdesk';
import Basket from './Basket';
import Favorites from './Favorites';
import LangSwitch from './LangSwitch';
import ScrollTop from './ScrollTop';
import Thuiswinkel from './Thuiswinkel';
import TcmsWYSIWYG from '../../../tcms/Tags/TcmsWYSIWYG';
import { IoIosSearch, IoIosPerson } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';

const Menu = (props) => {
  const context = useContext(TcmsContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const filterContext = useContext(FilterContext);
  const balance = context.getBalance();
  const [headerClass, setHeaderClass] = useState('header-normal');
  const [isInputVisible, setInputVisible] = useState(false);
  const [search, setSearch] = useState('');
  const location = useLocation();
  const history = useHistory();
  let timeoutRef = null;
  const headerUsps = ['header_usp_1', 'header_usp_2', 'header_usp_3', 'header_usp_4'];
  const headerUspsActive = ['usp_1_active', 'usp_2_active', 'usp_3_active', 'usp_4_active'];

  // On breakpoint close hamburger
  useEffect(() => {
    setHamburgerActive(false);
  }, [context.flex]);

  useEffect(() => {
    if (context.customHeader) {
      setHeaderClass('header-custom');
    }
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    timeoutRef = setTimeout(() => {
      filterContext.setSearch(e.target.value);
      if (!location.pathname.startsWith(`/${context.webshopSlug}/webshop`)) {
        history.push(`/${context.webshopSlug}/webshop`);
      }
    }, 1500);
  };

  return (
    <>
      {/* The helpdesk thing */}
      <div className='hidden-sm'>
        <Helpdesk />
      </div>

      {/* The Left (mobile only) menu sidebar */}
      {context.mobile && (
        <Sidebar
          className='menu mobile sidebar'
          style={{ width: '20rem', top: '0px' }}
          position='left'
          visible={menuVisible}
          onHide={() => setMenuVisible(false)}
        >
          <div className='nav'>
            <MenuItems />
          </div>
        </Sidebar>
      )}

      <div className={headerClass}>
        {context.customHeader ?
          <div className='p-top-bar top'>
            <div className='container__large container__top'>
              <div className='p-grid'>
                <div className='p-col-6'>
                  <div className='usp'>
                    <Tcms>Welkom in de webshop!</Tcms>
                  </div>
                </div>
                <div className='p-col-6 p-justify-center'>
                  <div className={classNames('nav nav-top__menu', { 'is-active': hamburgerActive })}>
                    <MenuItems />
                  </div>
                  <LangSwitch />
                </div>
              </div>
            </div>
          </div>
          :
          <div className='p-top-bar top'>
            <div className='container__large c_large_transparent'>
              <div className='top-bar-custom'>
                <div className='usp'>
                  <Tcms>Welkom in de webshop!</Tcms>
                </div>
                <div className='right-section'>
                  <div className="search-container">
                    <IoIosSearch className='icon' onClick={() => setInputVisible(!isInputVisible)} />
                    <input
                      className={`search-input ${isInputVisible ? 'visible' : ''}`}
                      placeholder='Zoeken'
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className='balance-info'>
                    <img alt='' src='/media/icons/coins-white.svg' />
                    <Tcms>JE MAG NOG</Tcms> <span className='balance'>{balance}</span> <Tcms>PUNTEN BESTEDEN</Tcms>
                  </div>
                  {/* As per new design, commenting this out temporarily */}
                  {/* <div className="icons-container">
            <div className={classNames('music-wrapper', Tcms(musicVisible))}>
              <MusicToggle/>
              {context.editMode && <Tcms>{musicVisible}</Tcms>}
            </div>
            <LangSwitch/>
          </div> */}
                </div>
              </div>
            </div>
          </div>
        }

        {/* Top menu HTML */}
        <div className='nav-top top-menu-content'>
          <div className='container__large'>
            <div className='top-menu desktop'>
              {/* Hamburger */}
              <div className={classNames('hamburger hamburger--spin', { 'is-active': hamburgerActive })}>
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    setHamburgerActive(!hamburgerActive);
                  }}
                >
                  <div className='hamburger-box'>
                    <span className='hamburger-inner'></span>
                  </div>
                </a>
              </div>

              {/* Logo */}
              {context.editMode &&
              <span>Logo class: <Tcms>logo_class</Tcms></span>
              }
              <div className={'logo '+ Tcms('logo_class')}>
                <TcmsImg  onClick={(e) => {
                          e.preventDefault();
                          history.push(`/${context.webshopSlug}/home`);
                        }} src='/media/images/default/logo.png' alt='logo' />
              </div>

              {context.customHeader ? (
                <div className='menu-wrapper'>
                  <div className='nav-top__right'>
                    <div className='nav-top__right-item hidden-xs'>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/${context.webshopSlug}/bestellingen`);
                        }}
                      >
                        <TcmsImg
                          className='favorite'
                          style={{ width: '35px', height: '35px' }}
                          src='/media/icons/info.png'
                          alt='Mijn bestelling'
                        />
                      </a>
                    </div>
                    <div className='nav-top__right-item hidden-xs'>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/${context.webshopSlug}/favorites`);
                        }}
                      >
                        <TcmsImg
                          className='favorite'
                          style={{ width: '35px', height: '35px' }}
                          src='/media/icons/favorite.svg'
                          alt='Mijn favorieten'
                        />
                      </a>
                    </div>
                    {context.meta.no_price === 0 && (
                      <div className='basket menu-basket nav-top__right-item balance'>
                        <div className='hidden-xs'>
                          <Tcms>points_remaining</Tcms>
                        </div>
                        <div>
                          <img className='visible-xs' alt='' src='/media/icons/coins.svg' />
                          <span className='balance-amount'>{balance} </span>
                          <span className='hidden-xs'>
                            {balance === 1 ? <TcmsSpan>punt</TcmsSpan> : <TcmsSpan>punten</TcmsSpan>}
                          </span>
                        </div>
                      </div>
                    )}

                    {/* Basket */}
                    <div className='basket menu-basket nav-top__right-item'>
                      <Basket />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={classNames('nav nav-top__menu', { 'is-active': hamburgerActive })}>
                    <MenuItems />
                  </div>

                  {/* Top Nav Right */}
                  <div className='nav-top__right'>
                    <div className='basket menu-basket nav-top__right-item'>
                      <Favorites />
                    </div>

                    {/* Basket */}
                    <div className='basket menu-basket nav-top__right-item'>
                      <Basket />
                    </div>
                    <div className='basket menu-basket nav-top__right-item balance visible-xs'>
                      <img alt='' src='/media/icons/coins.svg' />
                      <span className='p-badge p-component p-badge-dot p-badge-success nav-top__counter'>{balance}</span>
                      <span className='text'>
                        {balance === 1 ? <TcmsSpan>punt</TcmsSpan> : <TcmsSpan>punten</TcmsSpan>}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {context.customHeader && (
          <div className='header-usps'>
            <div className='wrapper'>
              <div className='container__large'>
                <div className='p-grid p-justify-center'>
                  <div className={`p-col-12 p-lg-6 p-xl-3 usp-col usp-${Tcms(headerUspsActive[0])}`}>
                    <div className='p-d-flex p-align-center p-justify-center'>
                      <div className='usp-icon'>
                        <TcmsImg alt='Geef je geschenk cadeau' src='/media/icons/icon-give-gift.svg' />
                      </div>
                      <div className='header-usp'>
                        <TcmsWYSIWYG>{headerUsps[0]}</TcmsWYSIWYG>
                      </div>
                    </div>
                  </div>
                  <div className={`p-col-12 p-lg-6 p-xl-3 usp-col usp-${Tcms(headerUspsActive[1])}`}>
                    <div className='p-d-flex p-align-center p-justify-center'>
                      <div className='usp-icon'>
                        <TcmsImg alt='Schenk punten aan collega' src='/media/icons/icon-give-points.svg' />
                      </div>
                      <div className='header-usp'>
                        <TcmsWYSIWYG>{headerUsps[1]}</TcmsWYSIWYG>
                      </div>
                    </div>
                  </div>
                  <div className={`p-col-12 p-lg-6 p-xl-3 usp-col usp-${Tcms(headerUspsActive[2])}`}>
                    <div className='p-d-flex p-align-center p-justify-center'>
                      <div className='usp-icon'>
                        <TcmsImg alt='Mijn bestelling' src='/media/icons/icon-orders.svg' />
                      </div>
                      <div className='header-usp'>
                        <TcmsWYSIWYG>{headerUsps[2]}</TcmsWYSIWYG>
                      </div>
                    </div>
                  </div>
                  <div className={`p-col-12 p-lg-6 p-xl-3 usp-col usp-${Tcms(headerUspsActive[3])}`}>
                    <div className='p-d-flex p-align-center p-justify-center'>
                      <div className='usp-icon'>
                        <TcmsImg alt='Geef geschenk' src='/media/icons/icon-local-gift.svg' />
                      </div>
                      <div className='header-usp'>
                        <TcmsWYSIWYG>{headerUsps[3]}</TcmsWYSIWYG>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {context.editMode && context.customHeader && (
          <div className='header-usps-edit'>
            <div className='usp usp_1'>
              USP 1
              <div className='toggle_active'>
                <Tcms>{headerUspsActive[0]}</Tcms>
              </div>
              <div className='content'>
                <Tcms>{headerUsps[0]}</Tcms>
              </div>
            </div>
            <div className='usp usp_2'>
              USP 2
              <div className='toggle_active'>
                <Tcms>{headerUspsActive[1]}</Tcms>
              </div>
              <div className='content'>
                <Tcms>{headerUsps[1]}</Tcms>
              </div>
            </div>
            <div className='usp usp_3'>
              USP 3
              <div className='toggle_active'>
                <Tcms>{headerUspsActive[2]}</Tcms>
              </div>
              <div className='content'>
                <Tcms>{headerUsps[2]}</Tcms>
              </div>
            </div>
            <div className='usp usp_4'>
              USP 4
              <div className='toggle_active'>
                <Tcms>{headerUspsActive[3]}</Tcms>
              </div>
              <div className='content'>
                <Tcms>{headerUsps[3]}</Tcms>
              </div>
            </div>
          </div>
        )}
      </div>
      <ScrollTop />
      <Thuiswinkel />
      <div className={classNames('nav-top__overlay', { 'is-active': hamburgerActive })} />
    </>
  );
};

export default Menu;
