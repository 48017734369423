import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {TcmsContext} from "../../../contexts/TcmsContext";
import slug from "../../../services/slug";
import DialogLogout from "./DialogLogout";
import Tcms from "../../../tcms/Tcms";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import {useHistory} from "react-router-dom";
import {FilterContext} from "../../../contexts/FilterContext";
import VirtualDialogInfo from "../../virtual/dialogs/VirtualDialogInfo";
import VirtualDialogOrder from "../../virtual/dialogs/VirtualDialogOrder";
import VirtualDialogPortal from "../../virtual/dialogs/VirtualDialogPortal";

const MenuItems = () => {
  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)
  const history = useHistory()
  const refDialogLogout = useRef()
  const refVirtualDialogInfo = useRef()
  const refVirtualDialogOrder = useRef()
  const refVirtualDialogPortal = useRef()
  const [activeItem, setActiveItem] = useState()

  // actions in tabChanged()  below
  const items = useMemo(() => {

    const shopLabel = (context.virtualMarketSlug !== '' && context.loadedComponent === 'Webshop')
      ? '_shop_virtueel_'
      : '_shop_webshop_'

    if (context.customHeader && context.loadedComponent !== 'PageVirtualMarket') {
      
      let customShopLabel = (window.location.pathname !== context.webshopSlug+'/plein' && context.loadedComponent === 'Webshop')
      ? '_shop_plein_'
      : '_shop_webshop'
      if (!context.welcomeScreenIntro) {
        customShopLabel = shopLabel
      }

      return [
        {key: 'shop', label: customShopLabel, to: `/${context.webshopSlug}/webshop/geschenken`, 'icon': "/media/icons/webshop.png"},
        {key: 'portal', label: 'Voorwoord', to: `/${context.webshopSlug}/portal`, 'icon': "/media/icons/chat.png"},
        {key: 'info', label: 'Extra Info', to: `/${context.webshopSlug}/hoe-werkt-het`, 'icon': "/media/icons/info.png"},
        {key: 'logout', label: 'Uitloggen', to: 'logout', 'icon': "/media/icons/uitloggen.png"},
      ]
    } else {

      if (context.meta.wso_id === 887 || context.meta.wso_id == 768) {
        return [
          {key: 'shop', label: shopLabel, to: `/${context.webshopSlug}/webshop/geschenken`, 'icon': "/media/icons/webshop.png"},
          {key: 'portal', label: 'Voorwoord', to: `/${context.webshopSlug}/welkom`, 'icon': "/media/icons/chat.png"},
          {key: 'info', label: 'Extra Info', to: `/${context.webshopSlug}/hoe-werkt-het`, 'icon': "/media/icons/info.png"},
          {key: 'order', label: 'Mijn Bestelling', to: `/${context.webshopSlug}/bestellingen`, 'icon': "/media/icons/mijn_bestelling.png"},
          {key: 'logout', label: 'Uitloggen', to: 'logout', 'icon': "/media/icons/uitloggen.png"},
        ]
      } else {
        if (context.meta.wso_id === 725) {
          return [
            {key: 'shop', label: shopLabel, to: `/${context.webshopSlug}/webshop/geschenken`, 'icon': "/media/icons/webshop.png"},
            {key: 'info', label: 'Extra Info', to: `/${context.webshopSlug}/hoe-werkt-het`, 'icon': "/media/icons/info.png"},
            {key: 'order', label: 'Mijn Bestelling', to: `/${context.webshopSlug}/bestellingen`, 'icon': "/media/icons/mijn_bestelling.png"},
            {key: 'logout', label: 'Uitloggen', to: 'logout', 'icon': "/media/icons/uitloggen.png"},
          ]
        } else {
          return [
            {key: 'shop', label: shopLabel, to: `/${context.webshopSlug}/webshop/geschenken`, 'icon': "/media/icons/webshop.png"},
            {key: 'portal', label: 'Voorwoord', to: `/${context.webshopSlug}/portal`, 'icon': "/media/icons/chat.png"},
            {key: 'info', label: 'Extra Info', to: `/${context.webshopSlug}/hoe-werkt-het`, 'icon': "/media/icons/info.png"},
            {key: 'order', label: 'Mijn Bestelling', to: `/${context.webshopSlug}/bestellingen`, 'icon': "/media/icons/mijn_bestelling.png"},
            {key: 'logout', label: 'Uitloggen', to: 'logout', 'icon': "/media/icons/uitloggen.png"},
          ]
        }
      }


    }

  }, [context.webshopSlug, context.virtualMarketSlug, context.loadedComponent])

  // This is here for a hard page reload
  useEffect(() => {
    setActiveItem(items.findIndex(el => el.to === history.location.pathname))
  }, [items, history])

  // Nav clicked
  const tabChanged = (index) => {
    setActiveItem(index)
    switch (items[index].key) {
      case 'shop':
        filterContext.clear()
        const hc = context.products.categories.find(el => el.id === filterContext.activeHoofdCatId)

        if (context.customHeader && context.welcomeScreenIntro) {
          if(window.location.pathname !== context.webshopSlug+'/plein' && context.loadedComponent === 'Webshop'){
            // Have VM and page is Webshop .. navigate to VM
            history.push(`/${context.webshopSlug}/plein`)
          } else {
            // No VM or VM but page !== 'Webshop' navigate to webshop
            history.push(`/${context.webshopSlug}/webshop`)
          }
        } else {
          if(context.virtualMarketSlug !== '' && context.loadedComponent === 'Webshop'){
            // Have VM and page is Webshop .. navigate to VM
            history.push(`/${context.webshopSlug}/virtueel`)
          } else {
            // No VM or VM but page !== 'Webshop' navigate to webshop
            history.push(`/${context.webshopSlug}/webshop`)
          }
        }


        break
      case 'portal':
        if (context.pageIsVM) {
          refVirtualDialogPortal.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'info':
        if (context.pageIsVM) {
          refVirtualDialogInfo.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'order':
        if (context.pageIsVM) {
          refVirtualDialogOrder.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'logout':
        refDialogLogout.current.open()
        break
      case null:
        break
      default:

    }
  }
  // Nav items (HTML)
  const navHTML = () => {
    const balance = context.getBalance()
    return <ul>
      {items.filter(item => item.label != null).map((el, key) =>
        <>
          <li key={key} data-key={key} className={key === activeItem ? 'active' : ''}>
            <img className="menu-icon" alt="" src={el.icon}/>
            <button onClick={(e) => {
              e.preventDefault()
              tabChanged(key)
            }}>
              <Tcms>{el.label}</Tcms>
            </button>
          </li>
          {el.key === 'info' && context.customHeader &&
            <>
              <li key={997} className="visible-sm">
                <button>
                  <a style={{color: '#000', textDecoration:'none'}} href="/" onClick={(e) => {
                    e.preventDefault()
                    history.push(`/${context.webshopSlug}/favorites`)
                  }}><Tcms>Favorites</Tcms></a>
                </button>
              </li>
              <li key={998} className="visible-sm">
                <button>
                  <a style={{color: '#000', textDecoration:'none'}} href="/" onClick={(e) => {
                    e.preventDefault()
                    history.push(`/${context.webshopSlug}/bestellingen`)
                  }}><Tcms>Mijn Bestelling</Tcms></a>
                </button>
              </li>
            </>
          }
        </>
      )}
      {/* {context.meta.no_price === 0 &&
        <>
          {!context.customHeader &&
            <li key={999} className="balance-li"><span className="eco"><img alt="" src="/media/icons/coins.svg"/></span>{balance} &nbsp;
              {balance === 1 ?
                <TcmsSpan>punt</TcmsSpan>
              :
                <TcmsSpan>punten</TcmsSpan>
              }
            </li>
          }
        </>
      } */}
      <li key={996} className="visible-xs">
          <button>
            <a style={{color: '#000', textDecoration:'none'}} href="https://morethangifts.zendesk.com/hc/nl" target="_blank">Helpdesk</a>
          </button>
      </li>
      
    </ul>
  }


  return <>
    <DialogLogout ref={refDialogLogout}/>
    <VirtualDialogInfo ref={refVirtualDialogInfo}/>
    <VirtualDialogOrder ref={refVirtualDialogOrder}/>
    <VirtualDialogPortal ref={refVirtualDialogPortal}/>
    {navHTML()}
  </>


}
export default MenuItems
