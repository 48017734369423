import React, { useState, useEffect } from "react";
import "./GameScreenOne.scss";

const GameScreenOne = () => {
  const [timeLeft, setTimeLeft] = useState(5);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const quizQuestions = [
    {
      question: "What year was Bob Marley born?",
      options: ["1945", "1950", "1942"],
      answer: "1945",
    },
    {
      question: "Where was Bob Marley born?",
      options: ["Kingston", "Nine Mile", "Montego Bay"],
      answer: "Nine Mile",
    },
    {
      question: "Which group did Bob Marley start his career with?",
      options: ["The Wailers", "The Beatles", "The Rolling Stones"],
      answer: "The Wailers",
    },
    {
      question: "What was one of Bob Marley's main themes in his music?",
      options: ["Peace", "Anger", "Money"],
      answer: "Peace",
    },
    {
      question: "When did Bob Marley die?",
      options: ["1981", "1990", "1978"],
      answer: "1981",
    },
    {
      question: "What was Bob Marley an advocate for?",
      options: ["Human Rights", "War", "Politics"],
      answer: "Human Rights",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          setIsQuizStarted(true);
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format seconds to mm:ss format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Handle answer selection
  const handleAnswerClick = (selectedOption) => {
    const currentQuizQuestion = quizQuestions[currentQuestion];

    if (selectedOption === currentQuizQuestion.answer) {
      setScore((prevScore) => prevScore + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      alert(`Quiz finished! Your score is: ${score + 1}/${quizQuestions.length}`);
      // Optionally, reset quiz or perform other actions
      setCurrentQuestion(0);
      setScore(0);
      setIsQuizStarted(false);
      setTimeLeft(5); // Reset the timer
    }
  };

  return (
    <div className="game-screen">
      <div className="game-content">
        {!isQuizStarted ? (
          <div className="story-section">
            <h2>Story About Bob Marley</h2>
            <div className="story-text">
              <p>
                Bob Marley was born on February 6, 1945, in Nine Mile, Saint Ann
                Parish, Jamaica, to Norval Marley and Cedella Booker. His father was
                a white Jamaican originally from Sussex, England, whose family
                claimed Syrian Jewish origins. Norval provided financial support for
                his wife and child but seldom saw them, as he was often away. Bob
                Marley pursued music as a child and began his career in Kingston,
                Jamaica, in 1963 with the group The Wailers. He started a reggae
                revolution that popularized the genre worldwide. His music remains
                timeless and continues to inspire, focusing on themes of love,
                peace, and social justice. Despite facing racism and numerous
                challenges, Marley became an international icon, advocating for
                human rights and cultural unity. His belief in Rastafarianism shaped
                his life and music, blending religious devotion with soulful sounds.
                Marley's influence transcended music, impacting global cultures and
                movements. He died on May 11, 1981, but his legacy endures,
                celebrated for uniting people through song.
              </p>
            </div>
            <div className="timer">Time Left: {formatTime(timeLeft)}</div>
          </div>
        ) : (
          <div className="quiz-content">
            <h3>Quiz: Test Your Knowledge</h3>
            <div className="quiz-question">
              <div className="question-heading-container">
                <h2>{quizQuestions[currentQuestion].question}</h2>
              </div>
              <div className="quiz-options">
                {quizQuestions[currentQuestion].options.map((option, index) => (
                  <button
                    key={index}
                    className="quiz-option"
                    onClick={() => handleAnswerClick(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="image-section">
          <img
            src="https://th.bing.com/th/id/R.fff1cb20acbc28ba2023f7257ba06ce8?rik=b4pIBZBvvlsCaw&pid=ImgRaw&r=0"
            alt="Bob Marley"
            className="bob-image"
          />
        </div>
      </div>

    </div>
  );
};

export default GameScreenOne;
